import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getApiDefaultHeaders, getApiURL } from "../Base";
import { APIException } from "../model/APIException";
import { APIResponse } from "../model/APIResponse";
import { APIError } from "../model/horizon";
import { Country } from "../model/horizon/Country";

export const useCountries = () => {
  const { keycloak } = useKeycloak();

  return useQuery({
    queryKey: ["countries"],
    enabled: keycloak.authenticated && (keycloak.token ?? "").length > 0,
    queryFn: () =>
      fetch(`${getApiURL()}horizon-back/v1/countries`, {
        headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
      })
        .then((res) => {
          const response: APIResponse = {
            status: res.status,
            body: res.json()
          };
          return response;
        })
        .then((res) => {
          if (res.status === 200) {
            return res.body as Country[];
          }
          const apiError = res.body as APIError;
          throw new APIException(apiError.detail, res.status);
        })
  });
};
