import HorizonProvider from "@context/Horizon/HorizonContext";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { initGA } from "@utils/analytics";
import { StrictMode, useEffect } from "react";
import Router from "./AppRoutes";
import NewFutureToolsTheme from "./NewFutureToolsTheme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity
    }
  }
});

const App = () => {
  useEffect(() => {
    initGA();
  }, []);
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={NewFutureToolsTheme}>
          <CssBaseline />
          <HorizonProvider>
            <Router />
          </HorizonProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
};

export default App;
