export const getRolesByPrefix = (roles: string[], prefix: string): string[] => {
  return roles.filter((role) => role.includes(prefix));
};

export enum RESOURCE_ROLE_INDEX {
  COUNTRY = 0,
  RESOURCE = 1
}

export const getResourceInfoByRole = (
  role: string,
  prefix: string,
  resourceIndex: RESOURCE_ROLE_INDEX
): number => {
  const roleFmt = role.replaceAll(prefix, "");
  // Expected format <country_id>_<sector_id>
  const roleInfo = roleFmt.split("_");
  return parseInt(roleInfo[resourceIndex]);
};
