import { ReactNode } from "react";
import { AuthProvider } from "./auth";

export interface RootProviderProps {
  children: ReactNode;
}

export function RootProvider({ children }: RootProviderProps) {
  return <AuthProvider>{children}</AuthProvider>;
}
