import { HORIZON_GROUPS, SECTOR_ROLE_PREFIX } from "@constants/horizon";
import { RESOURCE_ROLE_INDEX, getResourceInfoByRole } from "@utils/roleUtils";
import { TFunction } from "i18next";

/**
 * getFilename: Function to get date formatted to be included in download file names
 */
export const getFilename = (): string => {
  const current_date: Date = new Date();
  const current_date_fmt = current_date.toISOString();
  return `${current_date_fmt.replaceAll("-", "_").replaceAll(":", "_").replace("Z", "")}`;
};

export const getHorizonModulePrefix = (
  role: string,
  t: TFunction<"translation", undefined>
): string => {
  const sectorId = getResourceInfoByRole(
    role,
    SECTOR_ROLE_PREFIX,
    RESOURCE_ROLE_INDEX.RESOURCE
  );

  if (sectorId in HORIZON_GROUPS) {
    return t(HORIZON_GROUPS[sectorId]);
  }
  return "";
};
