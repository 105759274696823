/** @jsxImportSource @emotion/react */
import { DOWNLOAD_REPORT_SELECTION } from "@constants/horizon";
import { HorizonContext } from "@context";
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useSectors } from "@services/api/horizon";
import { useCountries } from "@services/api/horizon/Countries";
import { useIndustryTrendMetricsExcel } from "@services/api/horizon/IndustryTrendMetrics";
import { getExcelDateFmt } from "@utils/ExcelUtils";
import CountrySelector from "@views/ViewHorizon/components/Selectors/CountrySelector";
import IndustrySelector from "@views/ViewHorizon/components/Selectors/IndustrySelector";
import KpiSelector from "@views/ViewHorizon/components/Selectors/KpiSelector";
import MultiBrandSelector from "@views/ViewHorizon/components/Selectors/MultiBrandSelector";
import TimePeriodSelector from "@views/ViewHorizon/components/Selectors/TimePeriodSelector";
import saveAs from "file-saver";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const IndustryTrendSelectors = () => {
  const { t } = useTranslation();
  const { currentCtx } = useContext(HorizonContext);
  const [clicked, setClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [excelOption, setExcelOption] = useState<null | boolean>(null);
  const openDownloadReportMenu = Boolean(anchorEl);

  const { data: sectors } = useSectors({ countryId: currentCtx.countryId });
  const { data: countries } = useCountries();

  const sectorNameById = useMemo(() => {
    const sectorsById: { [key: string]: string } = {};
    (sectors ?? []).forEach((sector) => {
      sectorsById[sector.id.toString()] = sector.label;
    });
    return sectorsById;
  }, [sectors]);

  const countryCodeById = useMemo(() => {
    const countryCodeById: { [key: string]: string } = {};
    (countries ?? []).forEach((country) => {
      countryCodeById[country.id.toString()] = country.code;
    });
    return countryCodeById;
  }, [countries]);

  const handleDownloadReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isFetchingExcel) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: DOWNLOAD_REPORT_SELECTION) => {
    const newOption = option === DOWNLOAD_REPORT_SELECTION.ALL;
    setExcelOption(newOption);
    setAnchorEl(null);
  };

  const {
    data: excelBlob,
    refetch: refetchExcel,
    isFetching: isFetchingExcel
  } = useIndustryTrendMetricsExcel({
    sectorId: currentCtx.sectorId,
    kpiId: currentCtx.kpiId,
    timePeriod: currentCtx.industryTrendCtx.timePeriod,
    countryId: currentCtx.countryId,
    allSectors: excelOption ?? false
  });

  const isEnabledDownloadReportButton: boolean = useMemo(() => {
    return (
      currentCtx.countryId.length > 0 &&
      currentCtx.kpiId.length > 0 &&
      currentCtx.industryTrendCtx.timePeriod.length > 0
    );
  }, [currentCtx]);

  const isEnabledDownloadSectorOption: boolean = useMemo(
    () => currentCtx.sectorId.length > 0,
    [currentCtx.sectorId]
  );

  const excelFileName = useMemo(
    () =>
      `Report_Industry_Trend_${countryCodeById[currentCtx.countryId] ?? currentCtx.countryId}_${excelOption ? "All_Sectors" : `${sectorNameById[currentCtx.sectorId] ?? currentCtx.sectorId.toString()}`}_${currentCtx.industryTrendCtx.timePeriod}_${getExcelDateFmt()}.xlsx`,
    [
      excelOption,
      sectorNameById,
      countryCodeById,
      currentCtx.countryId,
      currentCtx.sectorId,
      currentCtx.industryTrendCtx.timePeriod
    ]
  );

  useEffect(() => {
    if (excelBlob && clicked && !isFetchingExcel) {
      saveAs(excelBlob, excelFileName);
      setClicked(false);
      setExcelOption(null);
    }
  }, [excelFileName, excelBlob, clicked, isFetchingExcel]);

  useEffect(() => {
    if (isEnabledDownloadReportButton && excelOption !== null) {
      setClicked(true);
      refetchExcel();
    }
  }, [excelOption, refetchExcel, isEnabledDownloadReportButton]);

  return (
    <Box sx={{ height: "100%" }}>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          gap: 2,
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 2,
          paddingBottom: 4
        }}
        elevation={0}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Horizon
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <CountrySelector />
            <IndustrySelector />
            <KpiSelector />
            <MultiBrandSelector />
            <TimePeriodSelector />
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={handleDownloadReport}
          disabled={!isEnabledDownloadReportButton}
          sx={{ textTransform: "none" }}
        >
          {isFetchingExcel && (
            <CircularProgress size="1.5rem" sx={{ color: "white" }} />
          )}
          {!isFetchingExcel && t("horizon.industry_trend.download_report")}
        </Button>
        <Menu
          id="brand-equity-download-report-menu"
          anchorEl={anchorEl}
          open={openDownloadReportMenu}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          <MenuItem
            onClick={() =>
              handleMenuItemClick(DOWNLOAD_REPORT_SELECTION.CURRENT_SELECTION)
            }
            disabled={!isEnabledDownloadSectorOption}
          >
            {t("horizon.brand_equity.download.current_selection")}
          </MenuItem>
          {(sectors || []).length > 1 && (
            <MenuItem
              onClick={() => handleMenuItemClick(DOWNLOAD_REPORT_SELECTION.ALL)}
            >
              {t("horizon.brand_equity.download.all_sectors")}
            </MenuItem>
          )}
        </Menu>
      </Paper>
    </Box>
  );
};

export default IndustryTrendSelectors;
