export const NO_BRAND_ID = "-1";

export enum DOWNLOAD_REPORT_SELECTION {
  CURRENT_SELECTION = "CURRENT_SELECTION",
  ALL = "ALL"
}

export const RESOURCE_HORIZON = "horizon_";

export const RESOURCE_ROLE_ALL_PREFIX = "all";

export const SECTOR_ROLE_PREFIX = `${RESOURCE_HORIZON}sector_`;

export const BRAND_ROLE_PREFIX = `${RESOURCE_HORIZON}brand_`;

export const COUNTRY_ROLE_PREFIX = `${RESOURCE_HORIZON}country_`;

export const RESOURCE_INDUSTRY_TREND_PAGE = `${RESOURCE_HORIZON}industry_trend`;

export const RESOURCE_BRAND_EQUITY_PAGE = `${RESOURCE_HORIZON}brandequity`;

export const RESOURCE_FUNNEL_STATE_PAGE = `${RESOURCE_HORIZON}funnel_state`;

export const RESOURCE_SOCIOECONOMIC_PAGE = `${RESOURCE_HORIZON}socioeconomic`;

export const HORIZON_MODULES: string[] = [
  RESOURCE_INDUSTRY_TREND_PAGE,
  RESOURCE_BRAND_EQUITY_PAGE,
  RESOURCE_FUNNEL_STATE_PAGE,
  RESOURCE_SOCIOECONOMIC_PAGE
];

export const BANK_SECTOR_ID = 9;

export const ENERGY_SECTOR_ID = 18;

export const HORIZON_GROUPS: { [key: number]: string } = {
  [BANK_SECTOR_ID]: "horizon.sectors.bank",
  [ENERGY_SECTOR_ID]: "horizon.sectors.energy"
};
