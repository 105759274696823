import { changeLanguage } from "@i18n/i18n";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthClientEvent } from "@react-keycloak/core/lib/types";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ReactNode } from "react";

import keycloakClient from "../keycloak/keycloak";

export const options = {
  onLoad: "login-required"
};

export interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const handleKeycloakEvent = (event: AuthClientEvent) => {
    if (event === "onAuthSuccess") {
      const preferredLanguage = keycloakClient.tokenParsed?.locale || "en";
      changeLanguage(preferredLanguage);
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloakClient}
      initOptions={options}
      onEvent={handleKeycloakEvent}
      LoadingComponent={
        <Box className="flex w-screen h-screen items-center justify-center">
          <CircularProgress />
        </Box>
      }
    >
      {children}
    </ReactKeycloakProvider>
  );
}
