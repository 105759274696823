import {
  COUNTRY_ROLE_PREFIX,
  RESOURCE_ROLE_ALL_PREFIX,
  SECTOR_ROLE_PREFIX
} from "@constants/horizon";
import { IndustryPageTime } from "@constants/time";
import { useKeycloak } from "@react-keycloak/web";
import { RESOURCE_ROLE_INDEX, getResourceInfoByRole } from "@utils/roleUtils";
import React, { createContext, useState } from "react";
import {
  HorizonContextType,
  DefaultHorizonContextType,
  IHorizonContext
} from "./HorizonContextTypes";

export const HorizonContext = createContext<HorizonContextType>(
  DefaultHorizonContextType
);

const getDefaultCountrySector = (
  userRoles: string[]
): { countryId: string; sectorId: string } => {
  let countryId = "";
  let sectorId = "";
  const countryRoles = userRoles.filter((role) =>
    role.includes(COUNTRY_ROLE_PREFIX)
  );

  if (
    countryRoles.length === 1 &&
    !countryRoles.includes(`${COUNTRY_ROLE_PREFIX}${RESOURCE_ROLE_ALL_PREFIX}`)
  ) {
    countryId = countryRoles[0].replaceAll(COUNTRY_ROLE_PREFIX, "");
  }

  const sectorRoles = userRoles.filter((role) =>
    role.includes(SECTOR_ROLE_PREFIX)
  );

  if (
    sectorRoles.length === 1 &&
    !sectorRoles.includes(`${SECTOR_ROLE_PREFIX}${RESOURCE_ROLE_ALL_PREFIX}`)
  ) {
    sectorId = getResourceInfoByRole(
      sectorRoles[0],
      SECTOR_ROLE_PREFIX,
      RESOURCE_ROLE_INDEX.RESOURCE
    ).toString();
  }
  return { countryId, sectorId };
};

const HorizonProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { keycloak } = useKeycloak();

  const userRoles = keycloak.realmAccess?.roles || [];

  const { countryId, sectorId } = getDefaultCountrySector(userRoles);

  const [currentCtx, setCurrentCtx] = useState<IHorizonContext>({
    countryId,
    sectorId,
    kpiId: "",
    brandId: "",
    industryTrendCtx: {
      brandIds: [],
      timePeriod: IndustryPageTime.YTD
    },
    brandEquityCtx: {
      marketBrandIds: [],
      withTrend: false,
      withPrediction: false
    }
  });

  const saveCurrentCtx = (ctx: IHorizonContext) => {
    setCurrentCtx({ ...ctx });
  };

  return (
    <HorizonContext.Provider value={{ currentCtx, saveCurrentCtx }}>
      {children}
    </HorizonContext.Provider>
  );
};

export default HorizonProvider;
