import Routes from "@constants/routing";
import { useHorizonTabsConfig } from "@hooks/horizon";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
  Container,
  Toolbar,
  IconButton,
  Box,
  useTheme
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";

export type AppNavBarProps = {
  title: string;
  tabs: JSX.Element[];
};

const AppNavBar = ({ title, tabs }: AppNavBarProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const horizonTabsConfig = useHorizonTabsConfig();
  const MenuItems = horizonTabsConfig.map((tab) => {
    const formatTextToKebabCase = (text: string): string => {
      return text.trim().toLowerCase().replace(/\s+/g, "-");
    };
    const active = location.pathname === tab.path;
    return (
      <MenuItem
        key={formatTextToKebabCase(tab.label)}
        onClick={() => {
          setAnchorElNav(null);
          navigate(tab.path);
        }}
        sx={{
          color: active ? theme.palette.primary.main : "none"
        }}
      >
        <Typography textAlign="center">{tab.label}</Typography>
      </MenuItem>
    );
  });

  return (
    <AppBar
      position="static"
      sx={{
        height: theme.customLayoutHeight.appNavBarHeight,
        boxShadow: "none"
      }}
    >
      <Container maxWidth="xl" sx={{ height: "inherit" }}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            height: "inherit",
            gap: 4,
            alignItems: "stretch",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              display: "flex",
              minWidth: "max-content",
              alignItems: "center",
              gap: "1rem"
            }}
          >
            <Link to={Routes.Home}>
              <Typography
                variant="h5"
                color="white"
                sx={{
                  fontWeight: "bold",
                  fontFamily: ["Kadwa"]
                }}
              >
                Future Tools
              </Typography>
            </Link>
            <Divider
              sx={{
                my: 2
              }}
              orientation="vertical"
              color="white"
              variant="middle"
              flexItem
            />
            <Typography variant="h6" color="white" sx={{ fontStyle: "italic" }}>
              {title}
            </Typography>
          </Box>

          {/* Nav list non small screen */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              gap: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%"
            }}
          >
            {tabs}
            <Divider
              sx={{
                my: 2
              }}
              orientation="vertical"
              color="white"
              variant="middle"
              flexItem
            />
            <Button
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                color: theme.palette.primary.contrastText,
                "&:hover": {
                  boxShadow: "none"
                },
                "&:active": {
                  boxShadow: "none"
                }
              }}
              onClick={() => keycloak.logout()}
            >
              {t("common.logout")}
            </Button>
          </Box>

          {/* Nav list small screen */}
          <Box
            sx={{
              display: { xs: "flex", md: "none" }
            }}
          >
            <IconButton
              sx={{
                width: "4rem",
                minHeight: "auto"
              }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setAnchorElNav(event.currentTarget)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              sx={{
                display: { xs: "block", md: "none" },
                flexDirection: "column"
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",

                  flexDirection: "column"
                }}
              >
                {MenuItems}
                {keycloak.authenticated ? (
                  <>
                    <Divider
                      orientation="horizontal"
                      color="white"
                      variant="middle"
                      flexItem
                    />
                    <MenuItem onClick={() => keycloak.logout()}>
                      {t("common.logout")}
                    </MenuItem>
                  </>
                ) : null}
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppNavBar;
