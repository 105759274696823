import {
  BRAND_ROLE_PREFIX,
  COUNTRY_ROLE_PREFIX,
  RESOURCE_ROLE_ALL_PREFIX,
  SECTOR_ROLE_PREFIX
} from "@constants/horizon";
import { HorizonModulesConfig } from "@constants/horizonRouting";
import { useKeycloak } from "@react-keycloak/web";
import { RESOURCE_ROLE_INDEX, getResourceInfoByRole } from "@utils/roleUtils";
import { useMemo } from "react";

export const useHorizonMinRoles = () => {
  const { keycloak } = useKeycloak();

  const hasMinimumRoles: boolean = useMemo(() => {
    const userRoles = keycloak.realmAccess?.roles || [];
    const rolePage = Object.keys(HorizonModulesConfig);
    // Assert user has a country role, a sector role and a brand role
    if (
      !userRoles.some((role) => role.includes(COUNTRY_ROLE_PREFIX)) ||
      !userRoles.some((role) => role.includes(SECTOR_ROLE_PREFIX)) ||
      !userRoles.some((role) => role.includes(BRAND_ROLE_PREFIX)) ||
      !rolePage.some((pageRole) =>
        userRoles.some((userRole) => userRole.includes(pageRole))
      )
    ) {
      return false;
    }
    const countryRoles = userRoles.filter((role) =>
      role.includes(COUNTRY_ROLE_PREFIX)
    );
    if (
      countryRoles.includes(`${COUNTRY_ROLE_PREFIX}${RESOURCE_ROLE_ALL_PREFIX}`)
    ) {
      return true;
    }
    // Get all allowed countryIds
    const countryIds = countryRoles.map((role) => {
      const roleFmt = role.replaceAll(COUNTRY_ROLE_PREFIX, "");
      return parseInt(roleFmt);
    });

    const sectorRoles = userRoles.filter((role) =>
      role.includes(SECTOR_ROLE_PREFIX)
    );

    const brandRoles = userRoles.filter((role) =>
      role.includes(BRAND_ROLE_PREFIX)
    );

    return countryIds.every((countryId) => {
      return (
        sectorRoles.some(
          (sectorRole) =>
            countryId ===
            getResourceInfoByRole(
              sectorRole,
              SECTOR_ROLE_PREFIX,
              RESOURCE_ROLE_INDEX.COUNTRY
            )
        ) &&
        brandRoles.some(
          (brandRole) =>
            countryId ===
            getResourceInfoByRole(
              brandRole,
              BRAND_ROLE_PREFIX,
              RESOURCE_ROLE_INDEX.COUNTRY
            )
        )
      );
    });
  }, [keycloak.realmAccess]);

  return hasMinimumRoles;
};
