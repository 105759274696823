import RoutesPaths from "@constants/routing";
import { trackPageView } from "@utils/analytics";
import ViewFallback from "@views/ViewFallback/ViewFallback";
import ViewHome from "@views/ViewHome";
import ViewHorizon from "@views/ViewHorizon";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

const Router = () => {
  return (
    <BrowserRouter>
      <AnalyticsTracker />
      <Routes>
        <Route path={RoutesPaths.Home} element={<ViewHome />} />
        <Route path={`${RoutesPaths.Horizon}/*`} element={<ViewHorizon />} />
        <Route path="*" element={<ViewFallback variant="not-found" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
