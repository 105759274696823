import SnackBar from "@components/SnackBar";
import {
  CHART_COLORS_FUTURE,
  CHART_COLORS_PAST
} from "@constants/horizonChartColors";
import { AUTO_HIDE_ALERT_ERROR } from "@constants/time";
import { HorizonContext } from "@context";
import SwipeIcon from "@mui/icons-material/Swipe";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  SnackbarCloseReason,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useSectors } from "@services/api/horizon";
import { useBrandEquityMetrics } from "@services/api/horizon/BrandEquityMetrics";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MultiLineChartCard from "../MultiLineChartCard";
import { processBrandEquityLineChartData } from "./utils";
import { BrandEquityLineChartData } from "../MultiLineChartCard/types";

const BrandEquityCharts = () => {
  const { t } = useTranslation();
  const { currentCtx } = useContext(HorizonContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const handleCloseErrorAlert = (
    _event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
  };

  const { data: sectors } = useSectors({ countryId: currentCtx.countryId });

  const { data, isLoading } = useBrandEquityMetrics({
    kpiId: currentCtx.kpiId,
    brandId: currentCtx.brandId,
    marketBrandIds: currentCtx.brandEquityCtx.marketBrandIds,
    predict: currentCtx.brandEquityCtx.withPrediction,
    trend: currentCtx.brandEquityCtx.withTrend,
    sectorId: currentCtx.sectorId
  });

  const brandsById: { [key: string]: string } = useMemo(() => {
    const brandsByIdTmp: Record<string, string> = {};
    (sectors ?? []).forEach((sector) => {
      sector.brands.forEach((brand) => {
        brandsByIdTmp[brand.id.toString()] = brand.label;
      });
    });
    return brandsByIdTmp;
  }, [sectors]);

  const { chartData, lastDateWithData } = useMemo(() => {
    const chartDataTmp: BrandEquityLineChartData[] = [];
    let lastDateData = new Date();

    (data ?? []).forEach((brandData, index) => {
      const brandId = (
        brandData.brand_id ?? t("horizon.brand_equity.industry_avg_selector")
      ).toString();
      const lastBrandDataDate = processBrandEquityLineChartData(
        brandData,
        chartDataTmp,
        brandsById[brandId] ?? brandId,
        CHART_COLORS_PAST[index],
        CHART_COLORS_FUTURE[index]
      );
      if (lastBrandDataDate < lastDateData) {
        lastDateData = lastBrandDataDate;
      }
    });

    return { chartData: chartDataTmp, lastDateWithData: lastDateData };
  }, [t, data, brandsById]);

  const brandsWithNoPredictedDataMessage: string = useMemo(() => {
    const brandNoData = (data ?? [])
      .filter(
        (brandData) => !brandData.data.some((timeData) => timeData.predicted)
      )
      .map(
        (brandData) =>
          `${brandData.brand_id ? (brandsById[brandData.brand_id] ?? brandData.brand_id.toString()) : "Sector Avg"}`
      );
    if (brandNoData.length > 0 && currentCtx.brandEquityCtx.withPrediction) {
      setOpenErrorAlert(true);
    }
    return brandNoData.length > 0
      ? `${t("horizon.brand_equity.no_predicted_data_for_brands")}: ${brandNoData.join(" , ")}`
      : "";
  }, [data, brandsById, t, currentCtx.brandEquityCtx.withPrediction]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: "#F2F4F7",
        height: "100%",
        padding: 1,
        paddingBottom: isMobile ? 6 : 1
      }}
    >
      <SnackBar
        id="brand_equity"
        message={brandsWithNoPredictedDataMessage}
        color="error"
        open={openErrorAlert}
        autoHideDuration={AUTO_HIDE_ALERT_ERROR}
        handleClose={handleCloseErrorAlert}
      />
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            paddingLeft: "1rem",
            paddingRight: "1rem"
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            {t("horizon.brand_equity.brand_forecasting")}
          </Typography>
          {chartData.length > 0 ? (
            <Grid
              container
              sx={{
                height: "100%",
                overflowY: "clip",
                position: "relative"
              }}
            >
              <Grid item xs={12} sx={{ marginBottom: 1 }}>
                <MultiLineChartCard
                  data={chartData}
                  lastDateWithData={lastDateWithData}
                />
              </Grid>
              {isTablet ? (
                <SwipeIcon
                  sx={{
                    position: "absolute",
                    bottom: 12,
                    opacity: 0.4,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    color: theme.palette.primary.main
                  }}
                  fontSize="small"
                />
              ) : null}
            </Grid>
          ) : null}
        </Box>
      )}
    </Paper>
  );
};

export default BrandEquityCharts;
