import BrandEquityForecastView from "@views/ViewHorizon/views/BrandEquityForecastView";
import FunnelStateView from "@views/ViewHorizon/views/FunnelStateView";
import IndustryTrendView from "@views/ViewHorizon/views/IndustryTrendView";
import SocioeconomicEnvironmentView from "@views/ViewHorizon/views/SocioeconomicEnvironmentView";
import {
  RESOURCE_BRAND_EQUITY_PAGE,
  RESOURCE_FUNNEL_STATE_PAGE,
  RESOURCE_INDUSTRY_TREND_PAGE,
  RESOURCE_SOCIOECONOMIC_PAGE
} from "./horizon";

export enum HorizonRoutes {
  Home = "",
  BrandEquity = "/brand_equity_forecast",
  Socioeconomic = "/socioeconomic_environment",
  FunnelState = "/funnel_state"
}

export interface HorizonModuleConfig {
  path: string;
  component: () => JSX.Element;
  label: string;
  orderId: number;
}

export const HorizonModulesConfig: { [key: string]: HorizonModuleConfig } = {
  [RESOURCE_INDUSTRY_TREND_PAGE]: {
    path: HorizonRoutes.Home,
    component: IndustryTrendView,
    label: "horizon.tabs.industry_trend",
    orderId: 1
  },
  [RESOURCE_BRAND_EQUITY_PAGE]: {
    path: HorizonRoutes.BrandEquity,
    component: BrandEquityForecastView,
    label: "horizon.tabs.brand_equity_forecast",
    orderId: 2
  },
  [RESOURCE_SOCIOECONOMIC_PAGE]: {
    path: HorizonRoutes.Socioeconomic,
    component: SocioeconomicEnvironmentView,
    label: "horizon.tabs.socioeconomic_environment",
    orderId: 3
  },
  [RESOURCE_FUNNEL_STATE_PAGE]: {
    path: HorizonRoutes.FunnelState,
    component: FunnelStateView,
    label: "horizon.tabs.funnel_state",
    orderId: 4
  }
};
