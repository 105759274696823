import { HorizonModulesConfig } from "@constants/horizonRouting";
import { useKeycloak } from "@react-keycloak/web";
import { useMemo } from "react";
import { useHorizonMinRoles } from "./useHorizonMinRoles";

export type HorizonRouteConfig = {
  path: string;
  component: () => JSX.Element;
}[];

export const useHorizonRoutesConfig = (): HorizonRouteConfig => {
  const { keycloak } = useKeycloak();
  const hasUserMinimunRoles = useHorizonMinRoles();

  const horizonRoutes: HorizonRouteConfig = useMemo(() => {
    const userRoles = keycloak.realmAccess?.roles || [];
    if (!hasUserMinimunRoles || userRoles.length === 0) {
      return [];
    }

    return Object.keys(HorizonModulesConfig)
      .filter((module) => userRoles.includes(module))
      .map((module) => HorizonModulesConfig[module])
      .sort((a, b) => a.orderId - b.orderId);
  }, [hasUserMinimunRoles, keycloak.realmAccess]);

  return horizonRoutes;
};
