import { HorizonModulesConfig } from "@constants/horizonRouting";
import Routes from "@constants/routing";
import { useKeycloak } from "@react-keycloak/web";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const toFullPath = (module_path: string): string => {
  return `${Routes.Horizon}${module_path}`;
};

export type HorizonTabsConfig = {
  path: string;
  label: string;
}[];

export const useHorizonTabsConfig = (): HorizonTabsConfig => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const horizonTabs = useMemo(() => {
    const userRoles = keycloak.realmAccess?.roles || [];
    return Object.keys(HorizonModulesConfig)
      .filter((module) => userRoles.includes(module))
      .map((module) => {
        const moduleConfig = HorizonModulesConfig[module];
        return {
          ...moduleConfig,
          path: toFullPath(moduleConfig.path),
          label: t(moduleConfig.label)
        };
      })
      .sort((a, b) => a.orderId - b.orderId);
  }, [t, keycloak.realmAccess]);

  return horizonTabs;
};
