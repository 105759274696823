const DOMAIN_PRODUCTION = "future-tools.ispd.com";
const DOMAIN_STAGING = "future-tools.staging.ispd.com";

const defaults = {
  KEYCLOAK_URL: "https://auth.dev.future-tools.clients.dive.tech",
  KEYCLOAK_REALM: "dive",
  KEYCLOAK_CLIENT_ID: "horizon",
  API_URL: new URL(
    `${window.location.protocol}//${window.location.host}`
  ).toString(),
  GA_MEASUREMENT_ID: "G-H8N8D1MVD1"
};

const variables = {
  development: {
    ...defaults,
    KEYCLOAK_URL:
      (process.env.REACT_APP_KEYCLOAK_URL as string) || defaults.KEYCLOAK_URL,
    KEYCLOAK_REALM:
      (process.env.REACT_APP_KEYCLOAK_REALM as string) ||
      defaults.KEYCLOAK_REALM,
    KEYCLOAK_CLIENT_ID:
      (process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string) ||
      defaults.KEYCLOAK_CLIENT_ID,
    API_URL: (process.env.REACT_APP_API_URL as string) || defaults.API_URL,
    GA_MEASUREMENT_ID: defaults.GA_MEASUREMENT_ID
  },
  staging: {
    KEYCLOAK_URL: "https://auth.future-tools.staging.ispd.com",
    KEYCLOAK_REALM: "ispd",
    KEYCLOAK_CLIENT_ID: "horizon",
    API_URL: defaults.API_URL,
    GA_MEASUREMENT_ID: defaults.GA_MEASUREMENT_ID
  },
  production: {
    KEYCLOAK_URL: "https://auth.future-tools.ispd.com",
    KEYCLOAK_REALM: "ispd",
    KEYCLOAK_CLIENT_ID: "horizon",
    API_URL: defaults.API_URL,
    GA_MEASUREMENT_ID: "G-QT0S5WX64S"
  }
};

const getVariables = () => {
  if (window.location.host === DOMAIN_PRODUCTION) {
    return variables.production;
  }

  if (window.location.host === DOMAIN_STAGING) {
    return variables.staging;
  }

  return variables.development;
};

export const {
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  API_URL,
  GA_MEASUREMENT_ID
} = getVariables();
