import ReactGA from "react-ga4";
import { GA_MEASUREMENT_ID } from "../config";

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const trackPageView = (url: string) => {
  ReactGA.send({ hitType: "pageview", page: url });
};
