import { useHorizonRoutesConfig } from "@hooks/horizon";
import { Box } from "@mui/material";
import ViewFallback from "@views/ViewFallback/ViewFallback";
import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";

const ViewHorizon = () => {
  const horizonRoutes = useHorizonRoutesConfig();

  const routes = useMemo(() => {
    return horizonRoutes.map((route) => (
      <Route path={route.path} Component={route.component} />
    ));
  }, [horizonRoutes]);

  return (
    <Box>
      <Routes>
        {routes}
        {/* Not found page */}
        <Route path="*" element={<ViewFallback variant="not-found" />} />
      </Routes>
    </Box>
  );
};
export default ViewHorizon;
