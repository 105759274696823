import Select, {
  SingleSelectOption
} from "@components/Select/SingleSelect/Select";
import { IndustryPageTime, isFirstQuarterMonth } from "@constants/time";
import { HorizonContext } from "@context";
import HelpIcon from "@mui/icons-material/Help";
import { SelectChangeEvent } from "@mui/material";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const TimePeriodSelector = () => {
  const { t } = useTranslation();
  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);

  const currentDate = useMemo(() => new Date(), []);

  const handleTimePeriodChange = (event: SelectChangeEvent) => {
    saveCurrentCtx({
      ...currentCtx,
      industryTrendCtx: {
        ...currentCtx.industryTrendCtx,
        timePeriod: event.target.value as IndustryPageTime
      }
    });
  };

  const isQTDDisabled: boolean = useMemo(
    () => isFirstQuarterMonth(currentDate.getMonth()),
    [currentDate]
  );

  const quarterTooltip: string = useMemo(() => {
    if (isQTDDisabled) {
      return t("horizon.industry_trend.helpers.time_period.firstQuarter");
    }
    return "";
  }, [t, isQTDDisabled]);

  const timePeriods: SingleSelectOption[] = useMemo(() => {
    return Object.keys(IndustryPageTime).map((timeOpt) => ({
      id: timeOpt,
      label: timeOpt,
      disabled: timeOpt === IndustryPageTime.QTD && isQTDDisabled
    }));
  }, [isQTDDisabled]);

  return (
    <Select
      id="time-period-select"
      variant="outer-label"
      inputLabel={t("horizon.selectors.time_period")}
      value={currentCtx.industryTrendCtx.timePeriod}
      onChange={handleTimePeriodChange}
      options={timePeriods}
      tooltip={
        quarterTooltip.length > 0
          ? { icon: HelpIcon, message: quarterTooltip }
          : undefined
      }
    />
  );
};
export default TimePeriodSelector;
