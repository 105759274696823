import {
  IndustryPageTime,
  getQuarterFromMonth,
  getQuarterMonths
} from "@constants/time";
import { IndustryTrendMetric } from "@services/api/model/horizon";
import { TFunction } from "i18next";
import { CustomChartData, Tooltips } from "./types";
import { CustomTableData, Order } from "../Table/types";

export const toCustomChartData = (
  data: CustomTableData[]
): CustomChartData[] => {
  return data.map((item) => ({ label: item.label, value: item.value }));
};

export const toCustomTableData = (
  data: IndustryTrendMetric[],
  brandsById: { [key: string]: string },
  accessValueFn: (data: IndustryTrendMetric) => number,
  order: Order = "desc"
): CustomTableData[] => {
  return data
    .filter((brandData) => accessValueFn(brandData) !== null)
    .sort((a, b) =>
      order === "desc"
        ? accessValueFn(b) - accessValueFn(a)
        : accessValueFn(a) - accessValueFn(b)
    )
    .map((brandData, index) => ({
      id: index + 1,
      label: brandsById[brandData.brand_id] ?? brandData.brand_id.toString(),
      value: accessValueFn(brandData)
    }));
};

export const formatRankValue = (val: number | string): string => {
  return typeof val === "number" ? val.toFixed(2) : val;
};

export const getKpiChartTitle = (kpiName: string, year: number): string => {
  return `${kpiName} ${year}`;
};

const getQuarterTootip = (
  year: number,
  quarter: number,
  t: TFunction<"translation", undefined>
): string => {
  const quarterMonths = getQuarterMonths(quarter);
  return t("horizon.industry_trend.helpers.time_period.quarter", {
    year,
    quarter,
    months: quarterMonths
  });
};

const getYearTootip = (
  year: number,
  month: number,
  t: TFunction<"translation", undefined>
): string => {
  const monthDate = new Date(`${year}-${month}-01`);

  return t("horizon.industry_trend.helpers.time_period.year", {
    year,
    month: monthDate.toLocaleString("default", { month: "long" })
  });
};

export const getTooltipHelper = (
  t: TFunction<"translation", undefined>,
  selected: IndustryPageTime
): Tooltips => {
  const current_date: Date = new Date();

  const quarter = getQuarterFromMonth(current_date.getMonth() + 1);

  if (selected === IndustryPageTime.QTD) {
    return {
      current: getQuarterTootip(current_date.getFullYear(), quarter, t),
      predicted: getQuarterTootip(current_date.getFullYear() + 1, quarter, t)
    };
  }
  return {
    current: getYearTootip(
      current_date.getFullYear(),
      current_date.getMonth(),
      t
    ),
    predicted: getYearTootip(
      current_date.getFullYear() + 1,
      current_date.getMonth(),
      t
    )
  };
};
