import { Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const CustomSimulatorCard = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  padding: "1rem",
  boxShadow: theme.shadows[4],
  display: "flex",
  flexDirection: "column",
  minHeight: "200px",
  gap: "5px",
  transition: theme.customTransitions.colorTransition
}));

type SimulatorCardProps = {
  icon?: any;
  to: string;
  title: string;
  description: string;
  alignItems: "start" | "center" | "end";
  enabled: boolean;
};

const SimulatorCard = ({
  icon,
  to,
  title,
  description,
  alignItems = "start",
  enabled
}: SimulatorCardProps) => {
  const theme = useTheme();

  const cursor = enabled ? "pointer" : "default";
  const onHoverEffect = enabled
    ? {
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[8],
        color: theme.palette.primary.contrastText
      }
    : {};

  return (
    <Link to={enabled ? to : "#"} className="group">
      <CustomSimulatorCard
        sx={{
          alignItems,
          cursor,
          "&:hover": onHoverEffect
        }}
      >
        <Box>
          {icon ? (
            <img
              src={icon}
              alt={`${title} icon`}
              className="group-hover:fill-white"
            />
          ) : null}
        </Box>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </CustomSimulatorCard>
    </Link>
  );
};

export default SimulatorCard;
