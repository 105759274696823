import { API_URL } from "../../config";

export const getApiURL = (): string => {
  const envApiUrl = process.env.REACT_APP_API_URL ?? API_URL;
  return envApiUrl;
};

export const getApiDefaultHeaders = (
  apiToken: string
): { [header: string]: string } => {
  return {
    Authorization: `Bearer ${apiToken}`
  };
};
