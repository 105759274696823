import { NO_BRAND_ID } from "@constants/horizon";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getApiDefaultHeaders, getApiURL } from "../Base";
import { APIException } from "../model/APIException";
import { APIResponse } from "../model/APIResponse";
import { APIError } from "../model/horizon";
import { BrandEquityForecastMetrics } from "../model/horizon/BrandEquityMetrics";

export interface BrandEquityMetricsParams {
  kpiId: string;
  brandId: string;
  marketBrandIds: string[];
  predict: boolean;
  trend: boolean;
  sectorId: string;
}

export const useBrandEquityMetrics = ({
  kpiId,
  brandId,
  marketBrandIds,
  predict,
  trend,
  sectorId
}: BrandEquityMetricsParams) => {
  const { keycloak } = useKeycloak();
  const brandIdsStr = [...marketBrandIds, brandId]
    .map((id) => `${id}`)
    .join(",");

  const sectorAvg: boolean = [...marketBrandIds, brandId].includes(NO_BRAND_ID);

  return useQuery({
    queryKey: [
      "brand_equity",
      "metrics",
      kpiId,
      brandId,
      brandIdsStr,
      predict,
      trend,
      sectorId
    ],
    enabled:
      keycloak.authenticated &&
      (keycloak.token ?? "").length > 0 &&
      sectorId.length > 0 &&
      brandId.length > 0 &&
      kpiId.length > 0,
    queryFn: () =>
      fetch(
        `${getApiURL()}horizon-back/v1/metrics/brand_equity_forecast?&sector_id=${sectorId}&kpi_id=${kpiId}&predict=${predict}&trend=${trend}&brands_id=${brandIdsStr}&sector_avg=${sectorAvg}`,
        {
          headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
        }
      )
        .then((res) => {
          const response: APIResponse = {
            status: res.status,
            body: res.json()
          };
          return response;
        })
        .then((res) => {
          if (res.status === 200) {
            return res.body as BrandEquityForecastMetrics[];
          }
          const apiError = res.body as APIError;
          throw new APIException(apiError.detail, res.status);
        })
  });
};

export interface BrandEquityMetricsExcelParams {
  kpiId: string;
  sectorId: string;
  predict?: boolean;
  trend: boolean;
  countryId: string;
  allSectors: boolean;
}

export const useBrandEquityMetricsExcel = ({
  kpiId,
  sectorId,
  predict = true,
  trend,
  countryId,
  allSectors
}: BrandEquityMetricsExcelParams) => {
  const { keycloak } = useKeycloak();

  const sectorIdParam = allSectors
    ? `&country_id=${countryId}`
    : `&sector_id=${sectorId}`;

  return useQuery({
    queryKey: [
      "brand_equity",
      "metrics",
      "excel",
      sectorId,
      kpiId,
      trend,
      allSectors
    ],
    enabled: false,
    queryFn: () =>
      fetch(
        `${getApiURL()}horizon-back/v1/metrics/brand_equity_forecast/excel?kpi_id=${kpiId}${sectorIdParam}&predict=${predict}&trend=${trend}`,
        {
          headers: { ...getApiDefaultHeaders(keycloak.token ?? "") }
        }
      )
        .then((res) => {
          const response: APIResponse = {
            status: res.status,
            body: res.blob()
          };
          return response;
        })
        .then((res) => {
          if (res.status === 200) {
            return res.body as Blob;
          }
          const apiError = res.body as APIError;
          throw new APIException(apiError.detail, res.status);
        })
  });
};
