import {
  Box,
  IconButton,
  MenuItem,
  SvgIconTypeMap,
  Tooltip
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useMemo } from "react";
export interface SingleSelectOption {
  id: string;
  label: string;
  disabled?: boolean;
}

export interface SingleSelectOptionTooltip {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  message: string;
}

export interface SelectProps {
  id: string;
  variant: "inner-label" | "outer-label";
  inputLabel: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: SingleSelectOption[];
  tooltip?: SingleSelectOptionTooltip;
}

const CustomSelect = ({
  id,
  variant,
  inputLabel,
  value,
  onChange,
  options,
  tooltip
}: SelectProps) => {
  const items = useMemo(() => {
    return options.map((opt) => (
      <MenuItem
        value={opt.id}
        key={`${id}-${opt.id}`}
        disabled={opt.disabled ?? false}
      >
        {opt.label}
      </MenuItem>
    ));
  }, [id, options]);

  if (variant === "inner-label") {
    return (
      <FormControl fullWidth>
        <Box sx={{ display: "flex" }}>
          <InputLabel id={`${id}-helper-label`}>{inputLabel}</InputLabel>
          {tooltip && (
            <sup>
              <Tooltip title={tooltip.message}>
                <IconButton size="small">
                  <tooltip.icon color="info" fontSize="small" />
                </IconButton>
              </Tooltip>
            </sup>
          )}
        </Box>
        <Select
          labelId={`${id}-helper-label`}
          id={`${id}-helper`}
          value={value}
          onChange={onChange}
        >
          {items}
        </Select>
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <InputLabel id={`${id}-helper-label`}>{inputLabel}</InputLabel>
        {tooltip && (
          <sup>
            <Tooltip title={tooltip.message}>
              <IconButton size="small">
                <tooltip.icon color="info" fontSize="small" />
              </IconButton>
            </Tooltip>
          </sup>
        )}
      </Box>
      <FormControl fullWidth>
        <Select
          id={`${id}-helper`}
          value={value}
          onChange={onChange}
          displayEmpty
        >
          {items}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default CustomSelect;
