import futureToolsByISPDLogo from "@assets/images/icon-future-tools.svg";
import Routes from "@constants/routing";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { Button, Typography, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ViewFallbackProps {
  variant: "not-found" | "under-construction";
}
const ViewFallback = ({
  variant = "under-construction"
}: ViewFallbackProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  let IconComponent = HandymanOutlinedIcon;
  let message = t("common.under_construction");

  switch (variant) {
    case "not-found":
      IconComponent = SearchOffIcon;
      message = t("common.page_not_found");
      break;
    case "under-construction":
      IconComponent = HandymanOutlinedIcon;
      message = t("common.under_construction");
      break;
    default:
      break;
  }
  return (
    <div className="h-screen w-screen flex gap-20 flex-col items-center justify-center">
      <div className="flex flex-col md:flex-row gap-8 justify-center items-center">
        <div className="flex flex-col gap-4">
          <img
            className="w-full md:w-[346px] max-w-full h-auto block"
            src={futureToolsByISPDLogo}
            alt="Future Tools By ISPD Logo"
          />
          <Typography variant="body1">{t("hub_page.subtitle")}</Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="flex flex-col md:flex-row items-center md:gap-4">
          <IconComponent
            fontSize="large"
            sx={{ color: theme.palette.primary.main }}
          />
          <Typography variant="h5">{message}</Typography>
        </div>
      </div>
      <Button
        variant="outlined"
        size="small"
        onClick={() => navigate(Routes.Home)}
      >
        {t("common.goBack")}
      </Button>
    </div>
  );
};

export default ViewFallback;
