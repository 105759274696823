import { SingleSelectOption } from "@components/Select";
import Select from "@components/Select/SingleSelect/Select";
import { HorizonContext } from "@context";
import { SelectChangeEvent } from "@mui/material";
import { useCountries } from "@services/api/horizon/Countries";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const CountrySelector = () => {
  const { t } = useTranslation();
  const { currentCtx, saveCurrentCtx } = useContext(HorizonContext);

  const { data } = useCountries();

  const handleCountryChange = (event: SelectChangeEvent) => {
    const countryId = event.target.value;
    saveCurrentCtx({
      ...currentCtx,
      countryId,
      sectorId: "",
      brandId: "",
      brandEquityCtx: {
        ...currentCtx.brandEquityCtx,
        marketBrandIds: []
      }
    });
  };

  const countryItems: SingleSelectOption[] = useMemo(() => {
    const countriesFmt = (data ?? []).map((country) => {
      return {
        id: country.id.toString(),
        label: t(`horizon.countries.${country.code.toLocaleLowerCase()}`)
      };
    });
    return countriesFmt;
  }, [t, data]);

  return (
    <Select
      id="country-select"
      variant="outer-label"
      inputLabel={t("horizon.selectors.country")}
      value={currentCtx.countryId}
      onChange={handleCountryChange}
      options={countryItems}
    />
  );
};

export default CountrySelector;
