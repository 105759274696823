import { SECTOR_ROLE_PREFIX } from "@constants/horizon";
import { useKeycloak } from "@react-keycloak/web";
import { getRolesByPrefix } from "@utils/roleUtils";
import { getHorizonModulePrefix } from "@views/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useHorizonTitle = () => {
  const { t } = useTranslation();

  const { keycloak } = useKeycloak();

  const horizonTitle = useMemo(() => {
    const sectorRoles = getRolesByPrefix(
      keycloak.realmAccess?.roles || [],
      SECTOR_ROLE_PREFIX
    );
    const prefix =
      sectorRoles.length === 1 ? getHorizonModulePrefix(sectorRoles[0], t) : "";

    return `Horizon ${prefix}`;
  }, [t, keycloak.realmAccess]);

  return horizonTitle;
};
