/** @jsxImportSource @emotion/react */
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { useHorizonTabsConfig, useHorizonTitle } from "@hooks/horizon";
import LayoutDefault from "@layouts/LayoutDefault";
import React from "react";
import { useLocation } from "react-router-dom";
import LinkButton from "./LinkButton";

export type LayoutHorizonElement = HTMLDivElement;

export type LayoutHorizonProps = {
  selectors?: JSX.Element | EmotionJSX.Element | undefined;
  className?: string;
};

export const LayoutHorizon = React.forwardRef<
  LayoutHorizonElement,
  React.PropsWithChildren<LayoutHorizonProps>
>(({ selectors, children }, _) => {
  const location = useLocation();
  const horizonTabsConfig = useHorizonTabsConfig();
  const horizonTitle = useHorizonTitle();

  const horizonTabs: JSX.Element[] = horizonTabsConfig.map((tabConfig) => (
    <LinkButton
      path={tabConfig.path}
      label={tabConfig.label}
      active={location.pathname === tabConfig.path}
    />
  ));

  return (
    <LayoutDefault
      title={horizonTitle}
      tabs={horizonTabs}
      selectors={selectors}
    >
      {children}
    </LayoutDefault>
  );
});

export default LayoutHorizon;
